import router from "next/router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ColorButton } from "../components/features/common/components/Elements";
import Layout from "../components/features/Default/layout/layout";
import IconNotFound from "../public/assets/icons/IconNotFound";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="bg-white rounded-lg px-4 py-4 md:h-screen md:w-full sm:w-screen">
        <div className="flex flex-col items-center justify-center h-[100%]">
          <IconNotFound className="sm:w-full" />
          <ColorButton
            className="h-[40px] w-[166px] mt-[30px] text-[14px] font-[600]"
            onClick={() => router.push("/")}
          >
            <FormattedMessage id="common.bottombar.home" />
          </ColorButton>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
